@import url('variables.css');

/*LATO*/
@font-face {
    font-family: "Lato";
    src: url("fonts/Lato/Lato-Regular.ttf") format("font-truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Lato";
    src: url("fonts/Lato/Lato-Italic.ttf") format("font-truetype");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: "Lato";
    src: url("fonts/Lato/Lato-Bold.ttf") format("font-truetype");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Lato";
    src: url("fonts/Lato/Lato-BoldItalic.ttf") format("font-truetype");
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: "Lato";
    src: url("fonts/Lato/Lato-Light.ttf") format("font-truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Lato";
    src: url("fonts/Lato/Lato-LightItalic.ttf") format("font-truetype");
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: "Lato";
    src: url("fonts/Lato/Lato-Thin.ttf") format("font-truetype");
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: "Lato";
    src: url("fonts/Lato/Lato-ThinItalic.ttf") format("font-truetype");
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: "Lato";
    src: url("fonts/Lato/Lato-Black.ttf") format("font-truetype");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "Lato";
    src: url("fonts/Lato/Lato-BlackItalic.ttf") format("font-truetype");
    font-weight: 900;
    font-style: italic;
}


[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}
html {
  height: 100%;
}
body {
  font-family: 'Lato', Tahoma, sans-serif;
  padding-top: 72px;
  height: 100%;
}
input:read-only {
    cursor:not-allowed !important;
}
.a2 {
    color: var(--primaryColor);
    text-decoration: none;
}
    .a2:hover,
    .a2:focus,
    .a2:active {
        text-decoration: underline;
    }
.table-eml > thead > tr > th,
.table-eml > thead > tr > th > span {
  font-weight:bold;
}
.table-eml > thead > tr > th,
.table-eml > tbody > tr > th,
.table-eml > tbody > tr > td, 
.table-eml > thead > tr > th > span,
.table-eml > tbody > tr > td > span {
  color:var(--black) !important;
  vertical-align: middle !important;
}
.table-eml {
  background-color:rgba(255,255,255,0.75) !important;
  border:none !important;
}
.table-eml > thead > tr > th,
.table-eml > tbody > tr > th,
.table-eml > tbody > tr > td, 
.table-eml > thead > tr,
.table-eml > tbody > tr, 
.table-eml > thead,
.table-eml > tbody {
  background-color: transparent !important;
  border:none !important;
}
.table-eml-bordered {
    margin-bottom: 0;
}
.table-eml-bordered > thead > tr > th {
    border-bottom: none;
}
.table-eml-bordered > thead > tr > th,
.table-eml-bordered > tbody > tr > td {
    border-top: none;
    border-right: 1px solid var(--white);
    vertical-align: middle;
}
.table-eml-bordered th:last-of-type,
.table-eml-bordered td:last-of-type {
    border-right: none;
    background-color: transparent;
}
.text-white {
  color: var(--white);
}
.text-black {
  color: var(--black);
}
.text-thin {
    font-weight:100 !important;
}
.text-light {
    font-weight:300 !important;
}
.text-bold {
    font-weight: bold;
}
.text-transparent {
    color:transparent;
}
#main-content {
  margin-left: 0;
}
.main-offset {
  margin-left:0;
}
@media (min-width: 768px) {
  .main-offset {
    margin-left: 225px !important;
  }
}
.card-activation-button-panel {
    position: fixed;
    right: 0;
    bottom: 55px;
    background-color: var(--black);
    padding: 15px;
    z-index: 50;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
}
.sub-header {
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}
.navbar-brand > .h2 {
    text-transform: none;
    color:var(--primaryColor);
}
.sidebar {
    display: none;
}
.navbar-collapse .sidebar {
    display: block;
}
@media (min-width: 768px) {
  .sidebar {
    position: fixed;
    top: 72px;
    bottom: 0;
    left: 0;
    z-index: 1000;
    display: block;
    padding: 0 0 0 20px;
    overflow-x: hidden;
    overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
    background-color: var(--black);
    border-right: 1px solid var(--black);
    margin-bottom: 47px;
    display: block;
    width: 250px;
  }
}
.mobile-nav {
    float: none;
}
@media (min-width: 768px) {
  .mobile-nav {
    float: right;    
  }                              
}
.sidebar-toggler {
    /*-webkit-border-radius: 4px 0px 0px 4px;
    -moz-border-radius: 4px 0px 0px 4px;
    border-radius: 4px 0px 0px 4px;*/
    cursor: pointer;
    display: block;
    float: right;
    margin: 0;
    border: 0;
    margin-top: 15px;
    margin-bottom: 15px;
    width: 24px;
    height: 23px;
    margin-right: 20px;
    background-color: var(--primaryColor);
}
.sidebar-header {
    background-image: url('../images/Optimized-LoginImage.png');
    background-size: cover;
}
.nav-sidebar {
  /* margin-right: -21px;20px padding + 1px border */
  margin-bottom: 20px;
  margin-left: -20px;
  margin-right: -20px;
}
a span.glyphicon,
a i.fa {
    top: 2px !important;
}
button i.fa {
    margin-top: 4px !important;
}
a i.fa-link {
    display: none;
}
.collapse.in > .nav > li > a:hover {
    background-color: var(--black);
}
.collapse.in > .nav > li.active > a,
.collapse.in > .nav > li.active > a:active, 
.collapse.in > .nav > li.active > a:focus {
    background-color: var(--primaryColor) !important;
}
#tools-menu,
#search-menu,
#manage-cards-menu,
#fulfillment-menu {
  width: 100%;
}
@media (min-width: 768px) {
  #tools-menu,
  #search-menu,
  #manage-cards-menu,
  #fulfillment-menu {
    max-width: 225px;
  }
}
#tools-menu > .navbar-nav,
#search-menu > .navbar-nav,
#manage-cards-menu > .navbar-nav,
#fulfillment-menu > .navbar-nav {
  margin: 0 !important;
  width:100%;
}
.nav-sidebar > li > a {
    padding-right: 20px;
    color: var(--white);
    font-weight: 300;
}
.nav-sidebar > li > a > span,
.navbar-nav > li > a > span {
    padding-left: 10px;
}
.dropdown-menu > li > a:hover,
.nav-sidebar > li > a:hover {
    background-color: var(--black) !important;
    color: var(--primaryColor) !important;
}
.nav-sidebar > .active > a,
.nav-sidebar > .active > a:hover,
.nav-sidebar > .active > a:focus,
.customer-row.active > td {
    color: var(--white) !important;
    background-color: var(--primaryColor) !important;
    font-weight: 700;
}

.nav-sidebar .dropdown-menu {
    position: relative;
    min-width: 100%;
    margin: 0;
    border: none;
    box-shadow: none;
    background-color: var(--black);
    color: var(--white) !important;
    float: none;
    padding: 0;
}
.nav-sidebar .dropdown-menu > li > a:hover, 
.nav-sidebar .dropdown-menu > li > a:focus {
    background-color: var(--black);
    color: var(--white);      
}
.nav-sidebar .collapse.in {
    display: inline-block !important;
}
.nav-sidebar .dropdown-menu > li > a {
    color: var(--white);
    border: 1px solid var(--black);
    padding: 9px 0 9px 30px;
}
.nav-sidebar .collapse > .navbar-nav > li > a, .navbar-nav > li > a {
    color: var(--white) !important;
}
.nav-sidebar .collapse > .navbar-nav > li {
    width:100% !important;
}
@media (max-width: 767px){
  .home-menu {         
    background-color: var(--white) !important;   
   }      
   
   .nav-homepage > li > a {
     color: var(--primaryColor) !important;
   } 
   
   .nav-homepage > li > a:hover,
   .nav-homepage > li > a:focus {
     background-color: #31b0d5;
     border-color: #269abc;
     color: var(--white) !important;
     font-weight: bold;
    }
    
    .nav-homepage.nav > li {
        border-top:2px solid  #269abc !important;        
    }
    .nav-homepage.nav {
        border-bottom:2px solid #269abc !important;
    }     
}

.home-menu {        
    margin-left:15px;
    float:right; 
    color: #08B7E1; 
    font-weight: bold;          
}

.home-image {       
    padding:0;
    width:63%;
    max-width: 619px;
    float:right;   
}

.home-image-container {
    display:inline-block;
    padding:0;
    margin-left:25px;
}

.nav-homepage > li > a:hover,
.nav-homepage > li > a:focus {
    background-color: #08B7E1; 
    border-color: #269abc;
    color: var(--white);
    font-weight: bold;    
}

.main {
  padding: 20px;
  margin-bottom:80px;
}
@media (min-width: 768px) {
  .main {
    padding-right: 40px;
    padding-left: 40px;
  }   
}
.main .page-header {
  margin-top: 0;
}

footer {
    position:fixed;
    bottom:0;
    left:0;
    background-color: var(--black);
    color:var(--white);
    z-index:1001;
    width:100%;
    border-top: 1px solid var(--black);
    padding-left: 15px;
    max-height: 47px;
}
footer img {
    margin-left:-30px;
}

footer li {
    margin-bottom: 0 !important;
}
.navbar ul.nav {
        padding-top: 0;
    }
@media (min-width: 768px) {
    .navbar ul.nav {
        padding-top: 14px;
    }
}
.sidebar ul.nav {
    padding-top: 0 !important;
}
.navbar-footer {
	min-height: 0;
	margin-bottom: 0;
	border: none;
}
.navbar-footer ul.nav {
    padding-top:0;
}
.error {
   color: #962624;
}
.error-asterisk {
  font-weight: bold;
  font-size: larger;
  vertical-align: text-top;
  margin-left: 3px;
}
.validation-well {
    padding:9px;
}
.notification {
    position:fixed;
    top:80px;
    left: 10%;
    right: 10%;
    z-index:10000;
}
@media (min-width: 768px) {
  .notification {
    top: 32px;
    left: 30%;
    right: 30%;
  }
}
/*.notification button.close {
    padding-left:10px;
}*/
.notification img {
    padding:0 10px 0 0;
}
/*.notification .alert {
    padding-right:40px;
}*/
.customer-search-results {
    max-height: 595px;
    overflow:auto;
}
.customer-search-edit-icon {
    margin-top:8px;
    font-size:large;
}
.text-indent-5 {
    text-indent:5px;
}
.text-indent-10 {
    text-indent:10px;
}
[ng\:cloak], [ng-cloak], .ng-cloak {
  display: none !important;
}
.even-card-trx {
    background-color:#F9F9F9;
}
.card-trx {
    border-bottom: 2px solid #DDD;
}
.card-trx-notes {
    padding-top: 12px;
}
.sort-icon {
    font-size: 12px;
    margin-left: 5px;
}
th, div.toggle-panel,
.customer-row, .fa-times {
    cursor:pointer;
}
.negative-amount {  
  color: red;
}
.negative-amount::before {  
  content: "(";
}
.negative-amount:after {  
  content: ")";
}
#merchant-group-location-indicator {
	 padding-top: 5px; 
}
#merchant-group-location-indicator .navbar-btn {
	margin: 0;
}
.navbar-btn a {
  width: 100% !important;
}
@media (min-width: 768px) {
  #merchant-group-location-indicator .navbar-btn {
	margin: 0;
  }
  .navbar-btn a {
  width: auto !important;
}
}
textarea.note {
  resize: none;
  margin-bottom: 2px;
}
.no-pad {
    padding: 0 !important;
}
.no-pad-left {
    padding-left: 0;
}
.left-divide {
    border-left: 2px solid rgba(51, 51, 51, 0.25);
}
.panel-heading input {
	color: var(--black);
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
}
button.refresh{
    border: transparent;
    background-color: transparent !important;
}
.nav-tabs > li.tab-alert,
.nav-tabs > li.tab-alert > a{
    color: var(--white) !important;
    background-color: red !important;
    border: 1px solid red !important;
    border-bottom-width: 0px !important;
    border-bottom-color: transparent !important;
}
div.pre-padding::before{
    content: "";
    margin-left: 6%;
}
.superscript {
    position: relative;
    bottom: 0.7em;
    font-size: 0.5em;
}
.badge.superscript {
    position: relative;
    bottom: 0.7em;
    font-size: 1em;
}
.in-progress {
    background-color: #f5f5f5 !important;
}
tr .fulfillment-card{
    border-width: 0px !important;
    background-color:  #f5f5f5 !important;  
    color: #777;     
}
tr .fulfillment-card.is-active, 
tr.fulfillment-card.ng-scope.warning.is-active {     
    border: 3px solid var(--primaryColor) !important;
    background-color:  var(--white) !important; 
    color: var(--black);    
}
tr .fulfillment-card.is-preactivated{     
    border-width: 0px !important;
    background-color:  var(--white) !important;  
    color: var(--black) !important;
    font-weight: bold;    
}

.no-border {
    border: none !important;
}

/** LOGIN PAGE **/
.login{
    background-color: var(--white);
    box-shadow: 5px 5px 5px rgba(51, 51, 51, 0.25);
    float: none; 
    margin: 3% auto;
    color: var(--black);
    padding-top: 30px !important;
    min-width: 275px !important;
    border-radius: 5%;
}

.login .brand {
    border: 1px solid var(--white);
    padding: 10px;
    font-weight: bold;
}

.login a,
.login .glyphicon {
    color: var(--primaryColor);
}
.login span.h2 {
    text-transform: none !important;
    color:var(--primaryColor);
}
.login h4 {
    padding-top: 20px;
    font-weight: bold;
    text-transform: uppercase;
    color:var(--black);
}

.login .input-group-addon {
    background-color: var(--primaryColor);
    opacity: 0.75;
    border: none;
    height: 40px;
}

.login .form-control {
    border: none;
    height: 40px;
    color: var(--black);
    background: rgba(51, 51, 51, 0.50);
}
@-webkit-keyframes autofill {
    to {
        color: var(--white);
        background: rgba(51, 51, 51, 0.50);
    }
}

@-moz-keyframes autofill {
    to {
        color: var(--white);
        background: rgba(51, 51, 51, 0.50);
    }
}

@keyframes autofill {
    to {
        color: var(--white);
        background: rgba(51, 51, 51, 0.50);
    }
}

.login input:-webkit-autofill, .login input {
    -webkit-animation-name: autofill;
    animation-name: autofill;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;    
}

@media (min-width: 768px) {
    .login{        
        width: 350px !important;  
    }    
}
/** END LOGIN PAGE**/

.list-group {
    background-color: transparent;
}
.list-group-item {
    background-color: transparent;
}
.list-group-item > a {
    color: var(--black);
}
.eml-container {
    background-color: var(--white);
    padding: 15px;
    margin-bottom: 30px !important;
    border: 1px solid var(--grey);
    box-shadow: 3px 3px 3px #777777;
    border-radius:7px;
}
.eml-container .activate-card-close {
    position:absolute;
    top:0;
    right:15px;
    z-index:2;
}
.flex-container-space-between {
    display:flex;
    justify-content:space-between;
}
.btn-danger-o {
  color: #962624;
  background-color: transparent; 
  border-color: #962624; /*#891917;*/
  font-weight: bold;
}
.btn-danger-o:focus,
.btn-danger-o.focus {
  color: var(--white);
  background-color: #891917;
  border-color: #700000;
}
.btn-danger-o:hover {
  color: var(--white);
  background-color: #891917;
  border-color: #7c0c0a;
  font-weight: bold;
}
.btn-danger-o:active,
.btn-danger-o.active,
.open > .dropdown-toggle.btn-danger-o {
  color: var(--white);
  background-color: #891917;
  border-color: #7c0c0a;
}
.btn-danger-o:active:hover,
.btn-danger-o.active:hover,
.open > .dropdown-toggle.btn-danger-o:hover,
.btn-danger-o:active:focus,
.btn-danger-o.active:focus,
.open > .dropdown-toggle.btn-danger-o:focus,
.btn-danger-o:active.focus,
.btn-danger-o.active.focus,
.open > .dropdown-toggle.btn-danger-o.focus {
  color: var(--white);
  background-color: #7c0c0a;
  border-color: #700000;
}
.btn-danger-o:active,
.btn-danger-o.active,
.open > .dropdown-toggle.btn-danger-o {
  background-image: none;
}
.btn-danger-o.disabled:hover,
.btn-danger-o[disabled]:hover,
fieldset[disabled] .btn-danger-o:hover,
.btn-danger-o.disabled:focus,
.btn-danger-o[disabled]:focus,
fieldset[disabled] .btn-danger-o:focus,
.btn-danger-o.disabled.focus,
.btn-danger-o[disabled].focus,
fieldset[disabled] .btn-danger-o.focus {
  background-color: #962624;
  border-color: #891917;
}
.btn-danger-o .badge {
  color: #962624;
  background-color: var(--white);
}
.btn-link > .fa {
    font-size:large;
}
@media (max-width: 1199px) {  
  .inline-input{
    width: 50% !important;
    } 
}
@media (min-width: 1200px) {  
  .inline-input{
    width: 30% !important;
    } 
}
.swipe-only {
    background-color: var(--white) !important;
}
@media print {    
    .container-fluid
    {
        display: none !important;
    }
}
.form-inline .ui-select-container .ui-select-toggle,
.form-inline .ui-select-container .ui-select-search {
  width: 100%;
}
.side-pane > ul > li.active {
    padding: 0;
}
.side-pane > ul > li {
    padding: 4px 0;
}
.side-pane > ul > li > a {
    color: var(--black);
}
.side-pane > ul > li > a:hover {
    cursor: pointer;
}

.qr-code img {
    margin: 0 auto;
}

.panel-heading img {
    padding-right: 10px;
}
.panel-heading h4 i.fa {
    padding-right: 5px;
}
.panel-heading a {
    cursor: pointer;
}
.panel-heading a:hover i.fa {
    color: #ccc;
}
.panel-body {
    background-color: var(--white);
}
/*.panel-body a,*/
.panel-body h1,
.panel-body h2,
.panel-body h3,
.panel-body h4,
.panel-body h5,
.panel-body h6 {
    color: var(--black);
}
.panel-body ul {
    list-style: none;
    padding: 0;
}

.panel-body ul > li > ul {
    padding-left: 15px;
    padding-bottom: 15px;
}

table:first-of-type {
    margin: 0 auto;
}

/******** TOGGLE SWITCH *********/
.switch {
    padding-top: 2px;
}
.form-horizontal .switch {
    padding-top: 9px;
}
.toggle {
  position: absolute;
  margin-left: -9999px;
  visibility: hidden;
}
.toggle + label {
  display: block;
  position: relative;
  cursor: pointer;
  outline: none;
  user-select: none;
}
input.toggle-round-flat + label {
  padding: 2px;
  width: 30px;
  height: 15px;
  background-color: #888;
  border-radius: 15px;
  transition: background 0.4s;
}
input.toggle-round-flat:disabled + label {
    background-color: #ccc;
    cursor: not-allowed;
}
input.toggle-round-flat + label:before,
input.toggle-round-flat + label:after {
  display: block;
  position: absolute;
  content: "";
}
input.toggle-round-flat + label:before {
  top: 2px;
  left: 2px;
  bottom: 2px;
  right: 2px;
  background-color: var(--white);
  border-radius: 15px;
  transition: background 0.4s;
}
input.toggle-round-flat + label:after {
  top: 4px;
  left: 4px;
  bottom: 4px;
  width: 8px;
  background-color: #888;
  border-radius: 14.5px;
  transition: margin 0.4s, background 0.4s;
}
input.toggle-round-flat:disabled + label:after {
    background-color: #ccc;
    cursor: not-allowed;
}
input.toggle-round-flat:checked + label {
  background-color: var(--primaryColor);
}
input.toggle-round-flat:checked:disabled + label {
    opacity: 0.50;
    cursor: not-allowed;
}
input.toggle-round-flat:checked + label:after {
  margin-left: 14px;
  background-color: var(--primaryColor);
}

input.toggle-round + label {
  padding: 2px;
  width: 30px;
  height: 15px;
  background-color: #dddddd;
  border-radius: 15px;
}
input.toggle-round + label:before,
input.toggle-round + label:after {
  display: block;
  position: absolute;
  top: 1px;
  left: 1px;
  bottom: 1px;
  content: "";
}
input.toggle-round + label:before {
  right: 1px;
  background-color: #f1f1f1;
  border-radius: 15px;
  transition: background 0.4s;
}
input.toggle-round:disabled + label:before {
    cursor: not-allowed;
    opacity: 0.75;
}
input.toggle-round + label:after {
  width: 14.5px;
  background-color: var(--white);
  border-radius: 100%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  transition: margin 0.4s;
}
input.toggle-round:disabled + label:after {
    cursor: not-allowed;
    opacity: 0.75;
}
input.toggle-round:checked + label:before {
  background-color: var(--primaryColor);
}
input.toggle-round:checked:disabled + label:before {
    opacity: 0.50;
    cursor: not-allowed;
}
input.toggle-round:checked + label:after {
  margin-left: 15px;
}
.switch-label {
    margin-left: 1rem;
    vertical-align: top;
}
.modal-content {
  border:none;
}
.modal-header h1, 
.modal-header h2, 
.modal-header h3, 
.modal-header h4, 
.modal-header h5, 
.modal-header h6{
    color:var(--white) !important;
}
.modal-body h1, 
.modal-body h2, 
.modal-body h3, 
.modal-body h4, 
.modal-body h5, 
.modal-body h6 {
    color:var(--black) !important;
}
@media (min-width:768px) {
    #confirmInventoryForm input[type="text"],
    #lookupGroupCardForm input[type="text"],
    #lookupCardForm input[type="text"],
    #preActivateGroupCardForm input[type="text"] {
        min-width: 270px !important;
    }
}

.element-animation {
  animation: animationFrames ease 1s;
  animation-iteration-count: 1;
  transform-origin: 50% 50%;
  animation-fill-mode:forwards; /*when the spec is finished*/
  -webkit-animation: animationFrames ease 1s;
  -webkit-animation-iteration-count: 1;
  -webkit-transform-origin: 50% 50%;
  -webkit-animation-fill-mode:forwards; /*Chrome 16+, Safari 4+*/ 
  -moz-animation: animationFrames ease 1s;
  -moz-animation-iteration-count: 1;
  -moz-transform-origin: 50% 50%;
  -moz-animation-fill-mode:forwards; /*FF 5+*/
  -o-animation: animationFrames ease 1s;
  -o-animation-iteration-count: 1;
  -o-transform-origin: 50% 50%;
  -o-animation-fill-mode:forwards; /*Not implemented yet*/
  -ms-transform-origin: 50% 50%;
  top:0;
  left: 0; /*Set to 200px for alert inside main-content*/
  right: 0;
}
@keyframes animationFrames{
    0% {
        opacity: 0;
        -moz-transform: translate(0px,-70px);
        -ms-transform: translate(0px,-70px);
        -o-transform: translate(0px,-70px);
        -webkit-transform: translate(0px,-70px);
        transform: translate(0px,-70px);
    }
    100% {
        opacity: 1;
        -moz-transform: translate(0px,0px);
        -ms-transform: translate(0px,0px);
        -o-transform: translate(0px,0px);
        -webkit-transform: translate(0px,0px);
        transform: translate(0px,0px);
    }
  /*100% {
    opacity:0;
    transform:  translate(0px,-70px)  ;
  }*/
}

@-moz-keyframes animationFrames{
    0% {
        opacity: 0;
        -moz-transform: translate(0px,-70px);
        -ms-transform: translate(0px,-70px);
        -o-transform: translate(0px,-70px);
        -webkit-transform: translate(0px,-70px);
        transform: translate(0px,-70px);
    }
    100% {
        opacity: 1;
        -moz-transform: translate(0px,0px);
        -ms-transform: translate(0px,0px);
        -o-transform: translate(0px,0px);
        -webkit-transform: translate(0px,0px);
        transform: translate(0px,0px);
    }
}

@-webkit-keyframes animationFrames {
    0% {
        opacity: 0;
        -webkit-transform: translate(0px,-70px);
        -moz-transform: translate(0px,-70px);
        -ms-transform: translate(0px,-70px);
        -o-transform: translate(0px,-70px);
        transform: translate(0px,-70px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translate(0px,0px);
        -moz-transform: translate(0px,0px);
        -ms-transform: translate(0px,0px);
        -o-transform: translate(0px,0px);
        transform: translate(0px,0px);
    }
}

@-o-keyframes animationFrames {
    0% {
        opacity: 0;
        -o-transform: translate(0px,-70px);
        -moz-transform: translate(0px,-70px);
        -ms-transform: translate(0px,-70px);
        -webkit-transform: translate(0px,-70px);
        transform: translate(0px,-70px);
    }
    100% {
        opacity: 1;
        -o-transform: translate(0px,0px);
        -moz-transform: translate(0px,0px);
        -ms-transform: translate(0px,0px);
        -webkit-transform: translate(0px,0px);
        transform: translate(0px,0px);
    }
}

@-ms-keyframes animationFrames {
    0% {
        opacity: 0;
        -ms-transform: translate(0px,-70px);
        -moz-transform: translate(0px,-70px);
        -o-transform: translate(0px,-70px);
        -webkit-transform: translate(0px,-70px);
        transform: translate(0px,-70px);
    }
    100% {
        opacity: 1;
        -ms-transform: translate(0px,0px);
        -moz-transform: translate(0px,0px);
        -o-transform: translate(0px,0px);
        -webkit-transform: translate(0px,0px);
        transform: translate(0px,0px);
    }
}
/*OWL CAROUSEL OVERRIDES*/
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
    background: var(--primaryColor);
}
.owl-item .eml-container {
    margin-bottom: 5px !important;
}

.sample-receipt table tr td table:first-of-type {
    border-right:1px solid #eee !important;
    border-left:1px solid #eee !important;
    margin-bottom: 20px !important;
}
/*COMMENTED CODE is something I'm working on for sample receipts*/
/*.sample-receipt table tr td table:first-of-type:before {
    content: " ";
    display:block;
    position: relative;
    top:0px;left:0px;
    width:100%;
    height:36px;
    background: linear-gradient(#C1ECF0 0%, transparent 0%), linear-gradient(135deg, var(--white) 33.33%, transparent 33.33%) 0 0%, var(--white) linear-gradient(45deg, var(--white) 33.33%, #C1ECF0 33.33%) 0 0%;
    background: -webkit-linear-gradient(#C1ECF0 0%, transparent 0%), -webkit-linear-gradient(135deg, var(--white) 33.33%, transparent 33.33%) 0 0%, var(--white) -webkit-linear-gradient(45deg, var(--white) 33.33%, #C1ECF0 33.33%) 0 0%;
    background: -o-linear-gradient(#C1ECF0 0%, transparent 0%), -o-linear-gradient(135deg, var(--white) 33.33%, transparent 33.33%) 0 0%, var(--white) -o-linear-gradient(45deg, var(--white) 33.33%, #C1ECF0 33.33%) 0 0%;
    background: -moz-linear-gradient(#C1ECF0 0%, transparent 0%), -moz-linear-gradient(135deg, var(--white) 33.33%, transparent 33.33%) 0 0%, var(--white) -moz-linear-gradient(45deg, var(--white) 33.33%, #C1ECF0 33.33%) 0 0%;
    background-repeat: repeat-x;
    background-size: 0px 100%, 9px 27px, 9px 27px;
}
.sample-receipt table tr td table:first-of-type:after {
    content: " ";
    display:block;
    position: relative;
    top:0px;left:0px;
    width:100%;
    height:36px;
    background: linear-gradient(var(--white) 0%, transparent 0%), linear-gradient(135deg, #C1ECF0 33.33%, transparent 33.33%) 0 0%, #C1ECF0 linear-gradient(45deg, #C1ECF0 33.33%, var(--white) 33.33%) 0 0%;
    background: -webkit-linear-gradient(var(--white) 0%, transparent 0%), -webkit-linear-gradient(135deg, #C1ECF0 33.33%, transparent 33.33%) 0 0%, #C1ECF0 -webkit-linear-gradient(45deg, #C1ECF0 33.33%, var(--white) 33.33%) 0 0%;
    background: -o-linear-gradient(var(--white) 0%, transparent 0%), -o-linear-gradient(135deg, #C1ECF0 33.33%, transparent 33.33%) 0 0%, #C1ECF0 -o-linear-gradient(45deg, #C1ECF0 33.33%, var(--white) 33.33%) 0 0%;
    background: -moz-linear-gradient(var(--white) 0%, transparent 0%), -moz-linear-gradient(135deg, #C1ECF0 33.33%, transparent 33.33%) 0 0%, #C1ECF0 -moz-linear-gradient(45deg, #C1ECF0 33.33%, var(--white) 33.33%) 0 0%;
    background-repeat: repeat-x;
    background-size: 0px 100%, 9px 27px, 9px 27px;
}*/
.sample-receipt table tr td table:first-of-type tr td table:first-of-type {
    border:none !important;
    margin-bottom:0 !important;
}
/*.sample-receipt table tr td table:first-of-type tr td table:first-of-type:before,
.sample-receipt table tr td table:first-of-type tr td table:first-of-type:after {
    background:none !important;
}*/

@media (max-width: 1200px) {
    .mobile-group-right {
      float: right;    
    }                              
  }

  @media (min-width: 1200px) {
    .button-group-spacer {
      margin-bottom: 25px;    
    } 
    .mobile-pull-right {
        float: right;    
    }                                
  }

  .eml-container.is-changed {
    background-color:  var(--primaryColor);
  }

  /*
  addressing inconsistent behavior with background pixelation using uibModal
  https://github.com/angular-ui/bootstrap/issues/6644
  */
  .modal-body,
  .modal-footer{
    background-color: var(--white);
  }
  .modal-header {
    background-color:var(--primaryColor);
  }
  
  .modal-backdrop{
    background-color: rgba(0, 0, 0, .99);
  }

  .dialog-header h1, 
  .dialog-header h2, 
  .dialog-header h3, 
  .dialog-header h4, 
  .dialog-header h5, 
  .dialog-header h6 {
      color:var(--black) !important;
  }
  
  text-center{ text-align:center;}

.sundry-row{
    padding: 0 0 10px 0;
}
.sundry-row > div{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.push-down{
    margin-top:15px;
}

em.error, em.character-count {
    float:right;
    padding-left:10px;
}

.bubble { 
    animation:pulse 1s 1; 
    -webkit-animation:pulse 1s 1;
  }

  @keyframes pulse {
    0%      { transform:scale(1); }
    25%     { transform:scale(1.25); }
    75%     { transform:scale(1); }
  }
  @-webkit-keyframes pulse {
    0%      { -webkit-transform:scale(1); }
    25%     { -webkit-transform:scale(1.25); }
    75%     { -webkit-transform:scale(1); }
  }

.label-display > label {
    padding-top: 20px;;
}

.ngdialog.ngdialog-theme-default .ngdialog-close
{
  border-color: transparent !important;
}

.my-slide-container {
    position:relative;
  }
  .my-slide-animation.ng-enter, .my-slide-animation.ng-leave {
    -webkit-transition:0.5s linear all;
    -moz-transition:0.5s linear all;
    -o-transition:0.5s linear all;
    transition:0.5s linear all;
  
    position:absolute;
    top:0;
    left:0;
    right:0;
    height:600px;
    overflow-x: hidden;
  }
  
  .my-slide-animation.ng-enter {
    z-index:100;
    top:600px;
    opacity:0;
  }
  .my-slide-animation.ng-enter.ng-enter-active {
    top:0;
    opacity:1;
  }
  
  .my-slide-animation.ng-leave {
    z-index:101;
    top:0;
    opacity:1;
  }
  .my-slide-animation.ng-leave.ng-leave-active {
    top:-600px;
    opacity:0;
  }

.hamburger {
    background-color: var(--black) !important;
    margin: 7px 7px 0 0 !important;
    border-radius: 7px;
}

.hamburger-inner, 
.hamburger-inner:after, 
.hamburger-inner:before {
    background-color: var(--primaryColor) !important;
}

.hamburger.is-active .hamburger-inner, 
.hamburger.is-active .hamburger-inner:after, 
.hamburger.is-active .hamburger-inner:before {
    background-color: var(--primaryColor) !important;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: 0; 
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--primaryColor); 
    border-radius: 20px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--primaryColor); 
  }

@media (min-width: 768px) {
    .hamburger {
      display: none !important;
    }
    .nav-sidebar {
        margin-right: 0 !important;
    }
    .my-slide-animation.ng-enter, .my-slide-animation.ng-leave {
        -webkit-transition:all cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s;
        -moz-transition:all cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s;
        -o-transition:all cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s;
        transition:all cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s;
    }
    .my-slide-animation.ng-enter {
        left:1200px;
        top:0;
      }
      .my-slide-animation.ng-enter.ng-enter-active {
        left:0;
        top:0;
      }
      .my-slide-animation.ng-leave {
        left:0;
        top:0;
      }
      .my-slide-animation.ng-leave.ng-leave-active {
        left:-1200px;
        top:0;
      }
}

.wizard .wizard-breadcrumb-label {
  padding: 10px 12px 10px;
  margin-right: 5px;
  background: var(--grey);
  position: relative;
  display: inline-block;
  text-decoration: none;
  outline: none;
}

.wizard .wizard-breadcrumb-label:before, .wizard .wizard-breadcrumb-label:disabled:before {
  width: 0;
  height: 0;
  border-top: 20px inset transparent;
  border-bottom: 20px inset transparent;
  border-left: 20px solid var(--white);
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  margin-left: -1px;
}

.wizard .wizard-breadcrumb-label:after {
  width: 0;
  height: 0;
  border-top: 20px inset transparent;
  border-bottom: 20px inset transparent;
  border-left: 20px solid var(--grey);
  position: absolute;
  content: "";
  top: 0;
  right: -20px;
  z-index: 2;
}

.wizard .wizard-breadcrumb-label:disabled::after {
  opacity: 1.65;
}

.wizard .wizard-breadcrumb-label:first-child:before, .wizard .wizard-breadcrumb-label:last-child:after {
  border: none;
}

.wizard .wizard-breadcrumb-label:first-child {
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
}

.wizard .wizard-breadcrumb-label:last-child {
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
}

.wizard .badge {
  margin: 0 5px 0 18px;
  position: relative;
  top: -1px;
}

.wizard .wizard-breadcrumb-label:first-child .badge {
  margin-left: 0;
}

.wizard .current {
  background: var(--primaryColor);
  color: var(--white);
}


.wizard .current .badge {
  background-color: var(--white);
  color: var(--primaryColor);
}

.wizard .current:after {
  border-left-color: var(--primaryColor);
}

.wizard .wizard-breadcrumb-label:active {
  outline:none;
}

.wizard .wizard-breadcrumb-label:hover {
  color: var(--primaryColor);
}

.wizard .current:hover {
  color: var(--white);
}

.checkbox-inline .form-check-input 
{
    height: 2.5rem;
    width: 2.5rem;
    top: -0.5rem;
}
.checkbox-inline .form-check-label 
{
    padding-left: 10px;
}

/*------>>> Animations <<<-----*/
.animate-switch-container {
  position:relative;
  overflow: hidden;
  min-height: 400px;
  background-size: 450px 450px;
}
.animate-switch{
  padding:10px;
  width: 100%;
}
.animate-switch.ng-animate {
  -webkit-transition:all 0.5s;
  transition:all 0.5s;
  position:absolute;

}
/* hide leaving slide  */
/* show */
.animate-switch.ng-leave{
  right:0;
}
/* hide */
.forward .animate-switch.ng-leave.ng-leave-active{
  right:-100%;
}
.backward .animate-switch.ng-leave.ng-leave-active{
  right: 100%;
}
/* show entering slide  */
/* hide */
.forward .animate-switch.ng-enter {
  right:100%;
}
.backward .animate-switch.ng-enter {
  right:-100%;
}
/* show */
.animate-switch.ng-enter.ng-enter-active {
  right:0;
}

.me-1 {
  margin-right: 5px;
}

#useOfCardsForm .checkbox-inline input[type="checkbox"] {
  scale: 1.6;
  cursor: pointer !important;
}

#useOfCardsForm input[type="text"] {
  vertical-align: text-top; 
  height:34px; 
  padding: 6px 12px; 
  font-size: 14px; 
  line-height: 1.42857143; 
  color: #555555; 
  background-color: 
  var(--white); 
  background-image:none;
  border:1px solid #CCC; 
  border-radius:8px;
}

#useOfCardsForm input[type="text"]:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, 0.6);
}

.vertical-align-middle,
tr.vertical-align-middle td {
  vertical-align: middle !important;
}
@import url(variables.css);

a {
  color: var(--primaryColor); /*OLD VALUE => #08B7E1;*/
  text-decoration:none;
}
a:hover,
a:focus,
a:active {
  text-decoration:underline;
}
h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6 {
  color: inherit; /*OLD VALUE => inherit;*/
  text-transform: uppercase; /*NEW PROP*/
}
.text-primary {
  color: var(--primaryColor); /*OLD VALUE => #08B7E1;*/
}
a.text-primary:hover,
a.text-primary:focus {
  color: #007787; /*OLD VALUE => #068fb0;*/
}
.text-warning {
  color: #d2822d;/*OLD VALUE #8a6d3b;*/
}
a.text-warning:hover,
a.text-warning:focus {
  color: #BD7529;/*OLD VALUE #66512c;*/
}
.bg-primary {
  color: #fff;
  background-color: var(--primaryColor); /*OLD VALUE => #08B7E1;*/
}
a.bg-primary:hover,
a.bg-primary:focus {
  background-color: #007787; /*OLD VALUE => #068fb0;*/
}
.bg-info {
  background-color: var(--primaryColor); /*OLD VALUE => #08B7E1;*/
}
a.bg-info:hover,
a.bg-info:focus {
  background-color: #007787; /*OLD VALUE => #068fb0;*/
}
.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xl-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xl-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xl-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xl-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xl-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xl-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xl-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xl-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xl-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xl-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xl-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12, .col-xl-12 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 1600px) {
  .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12 {
    float: left;
  }
  .col-xl-12 {
    width: 100%;
  }
  .col-xl-11 {
    width: 91.66666667%;
  }
  .col-xl-10 {
    width: 83.33333333%;
  }
  .col-xl-9 {
    width: 75%;
  }
  .col-xl-8 {
    width: 66.66666667%;
  }
  .col-xl-7 {
    width: 58.33333333%;
  }
  .col-xl-6 {
    width: 50%;
  }
  .col-xl-5 {
    width: 41.66666667%;
  }
  .col-xl-4 {
    width: 33.33333333%;
  }
  .col-xl-3 {
    width: 25%;
  }
  .col-xl-2 {
    width: 16.66666667%;
  }
  .col-xl-1 {
    width: 8.33333333%;
  }
  .col-xl-pull-12 {
    right: 100%;
  }
  .col-xl-pull-11 {
    right: 91.66666667%;
  }
  .col-xl-pull-10 {
    right: 83.33333333%;
  }
  .col-xl-pull-9 {
    right: 75%;
  }
  .col-xl-pull-8 {
    right: 66.66666667%;
  }
  .col-xl-pull-7 {
    right: 58.33333333%;
  }
  .col-xl-pull-6 {
    right: 50%;
  }
  .col-xl-pull-5 {
    right: 41.66666667%;
  }
  .col-xl-pull-4 {
    right: 33.33333333%;
  }
  .col-xl-pull-3 {
    right: 25%;
  }
  .col-xl-pull-2 {
    right: 16.66666667%;
  }
  .col-xl-pull-1 {
    right: 8.33333333%;
  }
  .col-xl-pull-0 {
    right: auto;
  }
  .col-xl-push-12 {
    left: 100%;
  }
  .col-xl-push-11 {
    left: 91.66666667%;
  }
  .col-xl-push-10 {
    left: 83.33333333%;
  }
  .col-xl-push-9 {
    left: 75%;
  }
  .col-xl-push-8 {
    left: 66.66666667%;
  }
  .col-xl-push-7 {
    left: 58.33333333%;
  }
  .col-xl-push-6 {
    left: 50%;
  }
  .col-xl-push-5 {
    left: 41.66666667%;
  }
  .col-xl-push-4 {
    left: 33.33333333%;
  }
  .col-xl-push-3 {
    left: 25%;
  }
  .col-xl-push-2 {
    left: 16.66666667%;
  }
  .col-xl-push-1 {
    left: 8.33333333%;
  }
  .col-xl-push-0 {
    left: auto;
  }
  .col-xl-offset-12 {
    margin-left: 100%;
  }
  .col-xl-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-xl-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-xl-offset-9 {
    margin-left: 75%;
  }
  .col-xl-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-xl-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-xl-offset-6 {
    margin-left: 50%;
  }
  .col-xl-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-xl-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-xl-offset-3 {
    margin-left: 25%;
  }
  .col-xl-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-xl-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-xl-offset-0 {
    margin-left: 0%;
  }
}
.table > thead > tr > td.info,
.table > tbody > tr > td.info,
.table > tfoot > tr > td.info,
.table > thead > tr > th.info,
.table > tbody > tr > th.info,
.table > tfoot > tr > th.info,
.table > thead > tr.info > td,
.table > tbody > tr.info > td,
.table > tfoot > tr.info > td,
.table > thead > tr.info > th,
.table > tbody > tr.info > th,
.table > tfoot > tr.info > th {
  background-color: var(--primaryColor); /*OLD VALUE => #08B7E1;*/
}
.table-hover > tbody > tr > td.info:hover,
.table-hover > tbody > tr > th.info:hover,
.table-hover > tbody > tr.info:hover > td,
.table-hover > tbody > tr:hover > .info,
.table-hover > tbody > tr.info:hover > th {
  background-color: #008494; /*OLD VALUE => #07a3c8;*/
}
.table > thead > tr > th, 
.table > tbody > tr > th, 
.table > tfoot > tr > th, 
.table > thead > tr > td, 
.table > tbody > tr > td, 
.table > tfoot > tr > td {
    border-top-color:var(--primaryColor);
}
.btn-primary {
  background-color: var(--primaryColor); /*OLD VALUE => #08B7E1;*/
  border-color: var(--primaryColor); /*OLD VALUE => #07a3c8;*/
}
/* .btn-primary:focus,
.btn-primary.focus {
  filter: brightness(0.6);
} */
.btn-primary:hover {
  filter: brightness(0.6);
}
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled.focus,
.btn-primary[disabled].focus,
fieldset[disabled] .btn-primary.focus {
  background-color: var(--primaryColor); /*OLD VALUE => #08B7E1;*/
  border-color: var(--primaryColor); /*OLD VALUE => #07a3c8;*/
}
.btn-primary .badge {
  color: var(--primaryColor); /*OLD VALUE => #08B7E1;*/
}
.btn-success {
  background-color: #65ac40;/*OLD VALUE => background-color: #5cb85c;*/
  border-color: #589F33;/*OLD VALUE => border-color: #4cae4c;*/
}
.btn-success:focus,
.btn-success.focus {
  background-color: #4c9327; /*OLD VALUE => #449d44;*/
  border-color: #196000; /*OLD VALUE => #255625;*/
}
.btn-success:hover {
  background-color: #4c9327; /*OLD VALUE => #449d44;*/
    border-color: #3f861a; /*OLD VALUE => #398439;*/
}
.btn-success:active,
.btn-success.active,
.open > .dropdown-toggle.btn-success {
  background-color: #4c9327; /*OLD VALUE => #449d44;*/
  border-color: #3f861a; /*OLD VALUE => #398439;*/
}
.btn-success:active:hover,
.btn-success.active:hover,
.open > .dropdown-toggle.btn-success:hover,
.btn-success:active:focus,
.btn-success.active:focus,
.open > .dropdown-toggle.btn-success:focus,
.btn-success:active.focus,
.btn-success.active.focus,
.open > .dropdown-toggle.btn-success.focus {
  background-color: #3f861a; /*OLD VALUE => #398439;*/
  border-color: #196000; /*OLD VALUE => #255625;*/
}
.btn-success.disabled:hover,
.btn-success[disabled]:hover,
fieldset[disabled] .btn-success:hover,
.btn-success.disabled:focus,
.btn-success[disabled]:focus,
fieldset[disabled] .btn-success:focus,
.btn-success.disabled.focus,
.btn-success[disabled].focus,
fieldset[disabled] .btn-success.focus {
  background-color: #65ac40; /*OLD VALUE => background-color: #5cb85c;*/
  border-color: #589F33; /*OLD VALUE => #4cae4c;*/
}
.btn-success .badge {
  color: #65ac40;/*OLD VALUE => color: #5cb85c;*/
}
.btn-warning {
  background-color: #d2822d; /*OLD VALUE #f0ad4e;*/
  border-color: #BD7529;/*OLD VALUE #eea236;*/
}
.btn-warning:focus,
.btn-warning.focus {
  background-color: #BD7529; /*OLD VALUE #ec971f;*/
  border-color: #A86824;/*OLD VALUE #985f0d;*/
}
.btn-warning:hover {
  background-color: #BD7529; /*OLD VALUE #ec971f;*/
  border-color: #A86824;/*OLD VALUE #d58512;*/
}
.btn-warning:active,
.btn-warning.active,
.open > .dropdown-toggle.btn-warning {
  background-color: #BD7529;/*OLD VALUE #ec971f;*/
  border-color: #A86824;/*OLD VALUE #d58512;*/
}
.btn-warning:active:hover,
.btn-warning.active:hover,
.open > .dropdown-toggle.btn-warning:hover,
.btn-warning:active:focus,
.btn-warning.active:focus,
.open > .dropdown-toggle.btn-warning:focus,
.btn-warning:active.focus,
.btn-warning.active.focus,
.open > .dropdown-toggle.btn-warning.focus {
  background-color: #A86824; /*OLD VALUE #d58512;*/
  border-color: #935B20;/*OLD VALUE #985f0d;*/
}
.btn-danger {
  background-color: #962624; /*OLD VALUE => #d9534f;*/
  border-color: #891917; /*OLD VALUE => #d43f3a;*/
}
.btn-danger:focus,
.btn-danger.focus {
  background-color: #891917; /*OLD VALUE => #c9302c;*/
  border-color: #700000; /*OLD VALUE => #761c19;*/
}
.btn-danger:hover {
  background-color: #891917; /*OLD VALUES => #c9302c;*/
  border-color: #7c0c0a; /*OLD VALUE => #ac2925;*/
}
.btn-danger:active,
.btn-danger.active,
.open > .dropdown-toggle.btn-danger {
  background-color: #891917; /*OLD VALUE => #c9302c;*/
  border-color: #7c0c0a; /*OLD VALUE => #ac2925;*/
}
.btn-danger:active:hover,
.btn-danger.active:hover,
.open > .dropdown-toggle.btn-danger:hover,
.btn-danger:active:focus,
.btn-danger.active:focus,
.open > .dropdown-toggle.btn-danger:focus,
.btn-danger:active.focus,
.btn-danger.active.focus,
.open > .dropdown-toggle.btn-danger.focus {
  background-color: #7c0c0a; /*OLD VALUE => #ac2925;*/
  border-color: #700000; /*OLD VALUE => #761c19;*/
}
.btn-danger.disabled:hover,
.btn-danger[disabled]:hover,
fieldset[disabled] .btn-danger:hover,
.btn-danger.disabled:focus,
.btn-danger[disabled]:focus,
fieldset[disabled] .btn-danger:focus,
.btn-danger.disabled.focus,
.btn-danger[disabled].focus,
fieldset[disabled] .btn-danger.focus {
  background-color: #962624; /*OLD VALUE => #d9534f;*/
  border-color: #891917; /*OLD VALUE => #d43f3a;*/
}
.btn-danger .badge {
  color: #962624; /*OLD VALUE => #d9534f;*/
}
.btn-link {
  color: var(--primaryColor); /*OLD VALUE => #08B7E1;*/
}
.caret {
  padding: 0 !important;
}
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  background-color: var(--primaryColor); /*OLD VALUE => #08B7E1;*/
}
.nav > li > a:hover,
.nav > li > a:focus {
  background-color: transparent;
  font-weight: 300;
}
.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
  border-color: var(--primaryColor); /*OLD VALUE => #08B7E1;*/
}
.nav-tabs {
  border-bottom: none;/*OLD VALUE => 1px solid #ddd;*/
  background-color: rgba(255, 255, 255, 0.50); /*Newly added for EML style*/
  padding: 0 15px 0; /*NEW PROP*/
}
.nav-tabs > li > a {
  color: #333; /*NEW PROPERTY ADDED*/
  text-transform: uppercase; /*NEW PROPERTY ADDED*/
}
.nav-tabs > li > a > span {
  vertical-align: middle; /*NEW PROP ADDED*/
}
.nav-tabs > li > a:hover {
  border-color: transparent; /*OLD VALUE => #eeeeee #eeeeee #ddd;*/
}
.nav-tabs > li > a:active,
.nav-tabs > li > a:focus {
  background-color: transparent !important; /*NEW RULE AND PROP ADDED*/
}
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  color: var(--black);
  background-color: transparent;
  border: none;
  /*OLD VALUE => border-bottom-color: transparent;*/
  border-bottom: 4px solid var(--primaryColor);
}
.nav-tabs.nav-justified > li > a:active,
.nav-tabs.nav-justified > li > a:focus {
    background-color: transparent;
}
@media (min-width: 768px) {
  .nav-tabs.nav-justified > li {
    border-bottom: 2px solid rgba(51,51,51,0.50); /*NEW PROP*/
  }
}
.nav-tabs.nav-justified > .active > a,
.nav-tabs.nav-justified > .active > a:hover,
.nav-tabs.nav-justified > .active > a:focus {
  border: none;
  border-bottom: 4px solid var(--primaryColor) !important;
}
@media (min-width: 768px) {
  .nav-tabs.nav-justified > li > a {
    border-bottom: none; /*OLD VALUE => 1px solid #ddd;*/
    margin-bottom: -3px; /*NEW PROP*/
  }
}
.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus {
  background-color: var(--primaryColor); /*OLD VALUE => #08B7E1;*/
}
.tab-content {
    background-color: rgba(255, 255, 255, 0.50); /*NEW RULE and PROPS*/
    padding: 0 15px 15px; /*OLD VALUE => 10px;*/
}
@media (min-width: 768px) {
  .navbar-header {
    padding: 11px; /*NEW PROP*/
  }
}
.navbar-fixed-top .navbar-collapse,
.navbar-fixed-bottom .navbar-collapse {
  max-height: 460px; /*340px; OLD VALUE*/
  overflow-x: hidden;
}
.navbar-inverse .navbar-collapse,
.navbar-inverse .navbar-form {
  border-color: var(--black);
}
.navbar-fixed-top {
  border: 0; /*OLD VALUE => border-width: 0 0 1px;*/
}
.navbar-brand {
  padding: 15px 15px; /*OLD VALUE => 13px 15px;*/
  height: 50px; /*OLD VALUE => 72px;*/
}
@media (min-width: 768px) {
  .navbar-brand {
    padding: 6px 10px;
  }
}
.navbar-brand > img {
  height: 35px; /*NEW PROP*/
}
.navbar-brand > span {
  line-height: 35px;
  vertical-align: middle;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 15px;
  font-size: 16px;
}
.navbar-nav {
  margin: 0px -20px;
}
@media (min-width: 768px) {
    .navbar-nav {
        margin: 6.5px -15px;
    }
}
@media (min-width: 768px) {
  .navbar-nav {
    float: left;
    margin: 0;
  }
  .navbar-right {
    float: right !important;
    margin-right: -15px;
  }
}
.navbar-fixed-bottom .navbar-nav > li > .dropdown-menu {
  border-top-right-radius: 0; /*OLD VALUE 4px;*/
  border-top-left-radius: 0; /*OLD VALUE 4px;*/
}
.navbar-inverse {
  background-color: #0B0B0B; /*OLD VALUE => #58595B; */
}
.pagination > li > a,
.pagination > li > span {
  color: var(--primaryColor); /* OLD VALUE => #08B7E1;*/
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
  border-bottom-left-radius: 0; /*OLD VALUE 4px;*/
  border-top-left-radius: 0; /*OLD VALUE 4px;*/
}
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-bottom-right-radius: 0; /*OLD VALUE 4px;*/
  border-top-right-radius: 0; /*OLD VALUE 4px;*/
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: var(--primaryColor); /*OLD VALUE => #08B7E1;*/
  border-color: var(--primaryColor); /*OLD VALUE => #08B7E1;*/
}
.pagination-lg > li:first-child > a,
.pagination-lg > li:first-child > span {
  border-bottom-left-radius: 0; /*OLD VALUE 6px;*/
  border-top-left-radius: 0; /*OLD VALUE 6px;*/
}
.pagination-lg > li:last-child > a,
.pagination-lg > li:last-child > span {
  border-bottom-right-radius: 0; /*OLD VALUE 6px;*/
  border-top-right-radius: 0; /*OLD VALUE 6px;*/
}
.pagination-sm > li:first-child > a,
.pagination-sm > li:first-child > span {
  border-bottom-left-radius: 0; /*OLD VALUE 3px;*/
  border-top-left-radius: 0; /*OLD VALUE 3px;*/
}
.pagination-sm > li:last-child > a,
.pagination-sm > li:last-child > span {
  border-bottom-right-radius: 0; /*OLD VALUE 3px;*/
  border-top-right-radius: 0; /*OLD VALUE 3px;*/
}
.label-primary {
  background-color: var(--primaryColor); /*OLD VALUE => #08B7E1;*/
}
.label-primary[href]:hover,
.label-primary[href]:focus {
  background-color: #007787; /*OLD VALUE => #068fb0;*/
}
.label-success {
  background-color: #65ac40; /*OLD VALUE => #5cb85c;*/
}
.label-success[href]:hover,
.label-success[href]:focus {
  background-color: #4c9327; /*OLD VALUE => #449d44;*/
}
.label-warning {
  background-color: #d2822d;/*OLD VALUE #f0ad4e;*/
}
.label-warning[href]:hover,
.label-warning[href]:focus {
  background-color: #BD7529;/*OLD VALUE #ec971f;*/
}
.label-danger {
  background-color: #962624; /*OLD VALUE => #d9534f;*/
}
.label-danger[href]:hover,
.label-danger[href]:focus {
  background-color: #891917; /*OLD VALUE => #c9302c;*/
}
.list-group-item.active > .badge,
.nav-pills > .active > a > .badge {
  color: var(--primaryColor); /*OLD VALUE => #08B7E1;*/
}
a.thumbnail:hover,
a.thumbnail:focus,
a.thumbnail.active {
  border-color: var(--primaryColor); /*OLD VALUE => #08B7E1;*/
}
.alert {
  border: none;
  border-radius: 0;
  padding: 25px 0;
  margin-bottom: 0 !important;
  position: fixed;
  top: 0; /*Set to 70px for alert inside main-content*/
  left: 0; /*Set to 200px for alert inside main-content*/
  right: 0;
  z-index: 9999;
  font-size: 1.3em;
  text-align: center;
}
.alert-dismissable,
.alert-dismissible {
  padding-right: 0;
}
.alert-dismissable .close,
.alert-dismissible .close {
  top: 25px;
  position: absolute;
  line-height: 30px;
  right: 40px;
}
.alert-success {
  background-color: #3c763d;
  border-color: #3c763d;
  color: #fff;
}
.alert-info {
  background-color: var(--primaryColor); /*OLD VALUE => #08B7E1;*/
}
.alert-warning {
  background-color: #d2822d;/*OLD VALUE #fcf8e3;*/
  border-color: #BD7529;/*OLD VALUE #faebcc;*/
  color: #fff;/*OLD VALUE #8a6d3b;*/
}
.alert-danger {
  background-color: #a94442;
  border-color: #a94442;
  color: #fff;
}
.alert-danger a {
    color:#fff;
}
.alert-danger a:hover, .alert-danger a:focus, .alert-danger:active {
    color:#d9d9d9;
}
.progress-bar {
  background-color: var(--primaryColor); /*OLD VALUE => #08B7E1;*/
}
.progress-bar-success {
  background-color: #65ac40; /*OLD VALUE => #5cb85c;*/
}
.progress-bar-danger {
  background-color: #962624; /*OLD VALUE => #d9534f;*/
}
.list-group-item:first-child {
  border-top-right-radius: 0; /*OLD VALUE 4px;*/
  border-top-left-radius: 0; /*OLD VALUE 4px;*/
}
.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0; /*OLD VALUE 4px;*/
  border-bottom-left-radius: 0; /*OLD VALUE 4px;*/
}
.list-group-item.active,
.list-group-item.active:hover,
.list-group-item.active:focus {
  background-color: var(--primaryColor); /*OLD VALUE => #08B7E1;*/
  border-color: var(--primaryColor); /*OLD VALUE => #08B7E1;*/
}
.list-group-item-info {
  background-color: var(--primaryColor); /*OLD VALUE => #08B7E1;*/
}
a.list-group-item-info:hover,
button.list-group-item-info:hover,
a.list-group-item-info:focus,
button.list-group-item-info:focus {
  background-color: #008494; /*OLD VALUE => #07a3c8;*/
}
.panel {
  background-color: transparent;/*OLD VALUE => background-color: #fff;*/
  border: none;/*OLD VALUE => border: 1px solid transparent;*/
}
.panel-body {
  background-color: rgba(255, 255, 255, 0.75);
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}
.panel-default > .panel-heading {
    background-color: var(--primaryColor);
    border-color: var(--primaryColor);
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}
.panel-primary > .panel-heading {
    background-color: var(--primaryColor); /*OLD VALUE => #08B7E1;*/
    border-color: var(--primaryColor); /*OLD VALUE => #08B7E1;*/
    border-top-left-radius: 7px;
    border-top-right-radius: 7px
}
.panel-primary > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: var(--primaryColor); /*OLD VALUE => #08B7E1;*/
}
.panel-primary > .panel-heading .badge {
  color: var(--primaryColor); /*OLD VALUE => #08B7E1;*/
}
.panel-primary > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: var(--primaryColor); /*OLD VALUE => #08B7E1;*/
}
.panel-info > .panel-heading {
  background-color: var(--primaryColor); /*OLD VALUE => #08B7E1;*/
}
.panel-info > .panel-heading .badge {
  color: var(--primaryColor); /*OLD VALUE => #08B7E1;*/
}
.panel-box-shadow {
  border: 1px solid var(--grey);
  box-shadow: 3px 3px 3px #777;
  border-radius: 7px;
}
.close {
  /*float: right;
  font-size: 21px;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.2;
  filter: alpha(opacity=20);*/
  background-color: #333;
  border-radius: 15px;
  font-size: 1.25em;
  font-weight: bold;
  color: #DDD;
  text-shadow: 1px 1px 5px #000;
  opacity: 0.2;
  height: 30px;
  filter: alpha(opacity=20);
  width: 30px;
  top: 25px;
  position: absolute;
  line-height: 30px;
  right: 40px;
  opacity: .2;
  box-shadow: inset 2px 2px 7px 2px #000;
}
.close:hover,
.close:focus {
  /*color: #000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.5;
  filter: alpha(opacity=50);*/
  background: #000;
  text-decoration: none;
  cursor: pointer;
  opacity: .2;
  color: #DDD;
}
.close:active {
    background-color:#666;
}
button.close {
  padding: 0;
  cursor: pointer;
  background:#333;
  border: 0;
  -webkit-appearance: none;
}
.list-group-item-success {
  color: var(--primaryColor);
}
a.list-group-item-success,
button.list-group-item-success {
  color: var(--primaryColor);
}

.popover-confirm {
  display: block;
  position: absolute;
  top: 10px;  /* Adjust based on where you want it to appear */
  left: 200px; /* Adjust based on the button's position */
  width: 200px;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 15px;
  border-radius: 4px;
  z-index: 1000;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}